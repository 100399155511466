.sticky-header thead th {
    top: 0;
    position: sticky;
    background: white;
    outline: 1px solid #e9ecef;
    border: none;
    outline-offset: -1px;
}

.min-width-75 th {
    min-width: 75px;
}
.required label:after {
    content:"*";
    color:red;
}

/* Remove arrows from input type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Workaround for active page in pagination being over filtering menu (CTW-10649) */
.page-item.active .page-link {
  z-index: 0;
}